<template>
  <div>
    <base-header type="gradient-warning" class="pb-6 pb-8 pt-5 pt-md-8" />
    <card>
      <template v-slot:header>
        <h3 class="modal-title" id="modal-title-default">
          List Changes by Status to: {{ payload.name }}
        </h3>
      </template>
      <card v-for="item in payload.messages" :key="item.id" class="mb-2">
        <template v-slot:header>
          <h3 class="modal-title" id="modal-title-default">
            <i class="fa fa-hand-o-right" aria-hidden="true"></i> Changes by Status
            {{ nameStatus(item.status_company) }}
          </h3>
        </template>
        <ul style="list-style-type: none" class="px-0">
          <li>
            <i class="fa fa-calendar" aria-hidden="true"></i> Date:
            {{ parseDate(item.created_at) }}
          </li>
          <li><i class="fa fa-user" aria-hidden="true"></i> User: {{ item.user.email }}</li>

          <li>
            <i class="fa fa-comments" aria-hidden="true"></i> Message:
            <span v-html="item.message"></span>
          </li>
        </ul>
      </card>
    </card>
  </div>
</template>
<script>
import CompaniesService from "features/companies/services/CompaniesService";
import moment from "moment";
import { resource } from "helpers";

export default {
  name: "FormCompanies",
  data: () => ({
    loading: false,
    message: "",
    status: "",
    showModal: false,
    titleModal: "Review Company",
    payload: {
      name: "",
      document: "",
      vat: "",
      email: "",
      mobileNumber: "",
      phoneNumber: "",
      vatNumber: "",
      customer: [],
      address: [],
      image: [],
      messages: [],
      status: "",
      documents: [],
    },
  }),
  computed: {
    isEdit() {
      return this.$route.name === "companies-edit" || "companies-review";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    parseDate(timesptamp) {
      return moment(timesptamp).format("DD/MM/yyyy HH:mm");
    },
    nameStatus(status) {
      switch (status) {
        case "I":
          return "In Review";
        case "W":
          return "Wait For Payment";
        case "R":
          return "Refused";
        case "E":
          return "Enabled";
        default:
          return "Disabled";
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { data } = await CompaniesService.listChangesStatus(this.$route.params.id);
        this.payload.name = data.name;
        this.payload.document = data.document;
        this.payload.email = data.email;
        this.payload.status = data.status;
        this.payload.phoneNumber = data.phone_number;
        this.payload.mobileNumber = data.mobile_number;
        this.payload.vatNumber = data.vat_number;
        this.payload.customer = data.customer;
        this.payload.messages = data.messages;
        this.payload.image = [resource(data.image)];
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
